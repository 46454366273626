<template>
  <a-drawer width="65%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <page-header-wrapper>
      <a-card :bordered="false">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="分类名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入分类名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="!advanced && 8 || 24" :sm="24">
                <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd(2,queryParam.parentId)">
            <a-icon type="plus" />新增
          </a-button>
          <table-setting
            :style="{float: 'right'}"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList" />
        </div>
        <!-- 增加修改 -->
        <create-form
          ref="createForm"
          @ok="getList"
        />

        <three-list
          ref="threeList"
          @ok="getList"
        />

        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :bordered="tableBordered"
        >

          <img style="width: 48px;height: 48px;" preview="头像" :src="record.image" v-if="record.image != null && record.image != ''" slot="image" slot-scope="text, record">

          <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
          <span slot="operation" slot-scope="text, record">
            <a-divider type="vertical" />
            <a @click="$refs.createForm.handleUpdate(record, undefined)">
              <a-icon type="edit" />修改
            </a>
            <a-divider type="vertical" />
            <a @click="handleDelete(record)">
              <a-icon type="delete" />删除
            </a>
            <a-divider type="vertical" />
            <a @click="$refs.threeList.show(record)">
              <a-icon type="pic-right" />下级分类
            </a>
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </a-card>
    </page-header-wrapper>
  </a-drawer>
</template>

<script>
  import { getGuildUserPage } from '@/api/biz/guild'

  import { tableMixin } from '@/store/table-mixin'


  export default {
    name: 'guildUserList',
    components: {

    },
    mixins: [tableMixin],
    data () {
      return {
        open: false,
        list: [],
        selectedRowKeys: [],
        selectedRows: [],
        // 高级搜索 展开/关闭
        advanced: false,
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        ids: [],
        loading: false,
        total: 0,
        // 查询参数
        queryParam: {
          name: null,
          sort: null,
          image: null,
          parentId: null,
          pageNum: 1,
          pageSize: 10
        },
        columns: [
          {
            title: '分类名称',
            dataIndex: 'name',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '图片',
            dataIndex: 'image',
            ellipsis: true,
            align: 'center',
            scopedSlots: {customRender: "image"}
          },
          {
            title: '排序',
            dataIndex: 'sort',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '插入时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center'
          }
        ]
      }
    },
    filters: {
    },
    created () {
      this.getList()
    },
    computed: {
    },
    watch: {
    },
    methods: {
      onClose () {
        this.open = false
      },
      // 取消按钮
      cancel () {
        this.open = false
        this.reset()
      },
      /** 查询商品-分类列表 */
      getList () {
        this.loading = true
        this.queryParam.level = 2
        getGuildUserPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      },
      /** 修改按钮操作 */
      show (row) {
        this.queryParam.parentId = row.id;
        this.open = true
        this.getList()
      },
      /** 搜索按钮操作 */
      handleQuery () {
        this.queryParam.pageNum = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery () {
        this.queryParam = {
          name: undefined,
          sort: undefined,
          image: undefined,
          pageNum: 1,
          pageSize: 10
        }
        this.handleQuery()
      },
      onShowSizeChange (current, pageSize) {
        this.queryParam.pageSize = pageSize
        this.getList()
      },
      changeSize (current, pageSize) {
        this.queryParam.pageNum = current
        this.queryParam.pageSize = pageSize
        this.getList()
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
        this.ids = this.selectedRows.map(item => item.id)
        this.single = selectedRowKeys.length !== 1
        this.multiple = !selectedRowKeys.length
      },
      toggleAdvanced () {
        this.advanced = !this.advanced
      },
      /** 删除按钮操作 */
      handleDelete (row) {
        var that = this
        const ids = row.id || this.ids
        if(row.id){
          this.ids.push(row.id)
        }
        this.$confirm({
          title: '确认删除所选中数据?',
          content: '当前选中编号为' + ids + '的数据',
          onOk () {
            let data = []
            that.ids.map(id => data.push({"id":id}))
            return delGoodsType(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  '删除成功',
                  3
                )
              })
          },
          onCancel () {
            //取消清空删除ids列表
            that.ids = []
          }
        })
      },
      /** 导出按钮操作 */
      handleExport () {
        var that = this
        this.$confirm({
          title: '是否确认导出?',
          content: '此操作将导出当前条件下所有数据而非选中数据',
          onOk () {
            that.download('platform/goods-type/export', {
              ...that.queryParam
            }, `商品-分类_${new Date().getTime()}.xlsx`)
          },
          onCancel () {}
        })
      }
    }
  }
</script>
